<template>
  <div>
    <el-dialog
      width="80%"
      :visible.sync="visible"
      append-to-body
    >
      <div class="hobbiesCpm-wrap">
        <div class="header-wrap">
          <div class="noday" v-if="selectArr.length === 0">
            已添加
          </div>
          <div class="selectArr">
            <div class="item" v-for="(item, index) in selectArr" :key="index">
              <template>
                <span>{{item.arr.text}}</span>
                <i class="el-icon-close" @click="handleCloseTab(item.arr.id)"></i>
              </template>
            </div>
          </div>
        </div>
        <div class="content">
          <div class="item" v-for="item in allHobbies" :key="item.id">
            <div class="title">
              {{item.title}}
            </div>
            <div class="tab">
              <div 
                v-for="tabItem in item.arr" 
                :key="tabItem.id"
                :class="['tabItem', selectId.indexOf(tabItem.id) > -1 ? 'activeClass' : '']"
                @click="handleTabItem(tabItem, item)"
              >
                {{tabItem.text}}
              </div>
            </div>
          </div>
        </div>
        <div class="btn-wrap">
          <div class="btn" @click="save">保存</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    hobbyStr: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      visible: false,
      activeHobbyStr: this.hobbyStr,
      allHobbies: [
        {
          title: '户外',
          id: 1,
          arr: [{id: 1, text: '旅游'}, {id: 2, text: '潜水'},{id: 3, text: '露营'}, {id: 4, text: '爬山'},{id: 5, text: '徒步'},{id: 6, text: '自驾'},{id: 7, text: '跳伞'},{id: 8, text: '攀岩'},{id: 9, text: '极限'},{id: 10, text: '逛街'},{id: 11, text: '垂钓'},{id: 12, text: '滑雪'}]
        },

        {
          title: '美食',
          id: 2,
          arr: [{id: 13, text: '火锅'}, {id: 14, text: '烧烤'},{id: 15, text: '中餐'}, {id: 16, text: '西餐'},{id: 17, text: '自助餐'},{id: 18, text: '快餐'},{id: 19, text: '面食'},{id: 20, text: '甜点'},{id: 21, text: '饮料'},{id: 22, text: '咖啡'},{id: 23, text: '土特产'},{id: 24, text: '零食'}]
        },
        {
          title: '运动',
          id: 3,
          arr: [{id: 25, text: '跑步'}, {id: 26, text: '游泳'},{id: 27, text: '健身'}, {id: 28, text: '高尔夫'},{id: 29, text: '乒乓球'},{id: 30, text: '羽毛球'},{id: 31, text: '足球'},{id: 32, text: '篮球'},{id: 33, text: '功夫'},{id: 34, text: '瑜伽'},{id: 35, text: '搏击'},{id: 36, text: '滑雪'}]
        },
        {
          title: '音乐',
          id: 4,
          arr: [{id: 37, text: '流行'}, {id: 38, text: '古典'},{id: 39, text: '摇滚'}, {id: 40, text: '爵士'},{id: 41, text: '校园'},{id: 42, text: '乡村'},{id: 43, text: '民族'},{id: 44, text: '影视'},{id: 45, text: '交响'},{id: 46, text: '声乐'},{id: 47, text: '器乐'},{id: 48, text: '电子'},{id: 49, text: '朋克'},{id: 50, text: 'RAP'},{id: 51, text: 'POP'}]
        },
        {
          title: '影视',
          id: 5,
          arr: [{id: 52, text: '电视剧'}, {id: 53, text: '综艺'},{id: 54, text: '纪录片'}, {id: 55, text: '现代'},{id: 56, text: '古风'},{id: 57, text: '国产'},{id: 58, text: '日韩'},{id: 59, text: '欧美'},{id: 60, text: '动作'},{id: 61, text: '科幻'},{id: 62, text: '仙侠'}, {id: 63, text: '武侠'},{id: 64, text: '喜剧'}, {id: 65, text: '爱情'},{id: 66, text: '恐怖'},{id: 67, text: '战争'},{id: 68, text: '剧情'},{id: 69, text: '微电影'}]
        },
        {
          title: '艺术',
          id: 6,
          arr: [{id: 70, text: '舞蹈'}, {id: 71, text: '绘画'},{id: 72, text: '表演'}, {id: 73, text: '摄影'},{id: 74, text: '美术'},{id: 75, text: '动画'},{id: 76, text: '书法'},{id: 77, text: '服装'},{id: 78, text: '主持'},{id: 79, text: '雕塑'},{id: 80, text: '文学'},{id: 81, text: '视觉'}, {id: 82, text: '听觉'}]
        },
        {
          title: '游戏',
          id: 7,
          arr: [{id: 83, text: '手机'}, {id: 84, text: 'pc'},{id: 85, text: '动作'}, {id: 86, text: 'fps'},{id: 87, text: '休闲'},{id: 88, text: '角色扮演'},{id: 89, text: '益智'},{id: 90, text: '网络'},{id: 91, text: '单机'},{id: 92, text: '主机'},{id: 93, text: '街机'},{id: 94, text: '桌游'}, {id: 95, text: '密室'},{id: 96, text: '游乐场'}, {id: 97, text: '真人CS'}]
        }
      ],
      selectArr: [],
      selectId: [],
      selectObj: null // 记录选中的类别的次数
    }
  },
  mounted () {
    this.iniHobby()
  },
  methods: {
    iniHobby () {
      let hobbyArr = this.activeHobbyStr.split(',')
      const allHobbies = this.allHobbies
      allHobbies.forEach(item => {
        item.arr.forEach(itemArr => {
          hobbyArr.forEach(itemHobby => {
            if (itemHobby === itemArr.text) {
              this.selectArr.push({
                title: item.title,
                id: item.id,
                arr: itemArr
              })
              this.selectId.push(itemArr.id)
            }
          })
        })
      })
    },
    handleTabItem (tabItem, item) {
      const id = tabItem.id
      const obj = item
      const itemDate = tabItem
      const selectArr = this.selectArr
      let selectId = this.selectId
      
      let selectObj = this.selectObj
      if (selectObj && selectObj[obj.id] >= 6) {
        this.$notify.error({
          title: '错误',
          message: '每个分类不能超过六个'
        });
      } else {
        if(selectArr.length === 0) {
          this.selectArr.push({
            title: obj.title,
            id: obj.id,
            arr: itemDate
          })
          this.selectId = [itemDate.id]
        } else {
          const flag = selectArr.every(item => {
            return item.arr.id !== id
          })
          
          if (flag) {
            this.selectArr.push({
              title: obj.title,
              id: obj.id,
              arr: itemDate
            })
            this.selectId.push(itemDate.id)
          }
    
        }
        this.selectObj = this.getArrItemNum(selectArr)
      }
    },
    getArrItemNum(arr) {
      console.log(arr);
      let obj = {}
      arr.forEach(item => {
        if (obj[item.id]) {
          obj[item.id]++
        } else {
          obj[item.id] = 1
        }
      })
      console.log(obj);
      return obj
    },
    handleCloseTab (id) {
      let selectArr = this.selectArr
      let selectId = this.selectId
      this.selectArr = selectArr.filter(item => {
        return item.arr.id !== id
      })
      this.selectId = selectId.filter(item => {
        return item !== id
      })
    },
    save() {
      this.visible = false
      this.$emit('save', this.selectArr)
    }
  },
  watch: {
    hobbyStr (newVal) {
      this.activeHobbyStr = newVal
      this.iniHobby()
    }
  }
}
</script>

<style scoped lang=less>
.hobbiesCpm-wrap {
  height: 100%;
  width: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  .header-wrap{
    padding: 0 10px 10px 10px;
    background-color: #fff;
    font-size: 14px;
    .selectArr {
      display: flex;
      flex-wrap: wrap;
      .item {
        position: relative;
        padding: 5px 20px;
        border: 1px solid #ccc;
        border-radius: 50px;
        margin-right: 10px;
        margin-bottom: 10px;
        .el-icon-close {
          position: absolute;
          right: -15px;
          top: -2px;
          width: 30px;
          height: 30px;
        }
      }
    }
  }
  .content {
    background-color: #fff;
    margin-top: 20px;
    padding: 0 20px;
    border-radius: 20px;
    .item {
      // background-color: red;
      font-size: 18px;
      .title {
        margin-bottom: 10px;
      }
      .tab {
        display: flex;
        flex-wrap: wrap;
        .tabItem {
          padding: 4px 10px;
          border: 1px solid #ccc;
          margin-right: 10px;
          margin-bottom: 10px;
        }
      }
    }
  }
  .btn-wrap {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0 20px;
    .btn { 
      height: 40px;
      width: 100px;
      background-color: #ffb5b5;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 15px;
    }
  }
  .activeClass {
    border: 1px solid red !important;
  }
}

</style>