<template>
  <div class="userCheck">
    <!-- <hearder-input
      @getUserList="getUserList"
      title="请输入用户微信用户id"
    ></hearder-input> -->
    <div class="header">
      <div class="search">
        <hearder-input
          @getUserList="searchBywxUsername"
          title="请输入用户id"
        ></hearder-input>
      </div>
      <div class="search">
        <hearder-input
          @getUserList="searchByusername"
          title="请输入用户姓名"
        ></hearder-input>
      </div>
    </div>
    <template>
      <el-table
        :data="userList"
        style="width: 100%"
        :header-cell-style="headClass"
      >
        <el-table-column fixed  prop="userId" label="用户id" width="100px"></el-table-column>
        <el-table-column prop="userNum" label="编号" width="150px"></el-table-column>
        <el-table-column prop="userInfoVo.userName" label="昵称" width="150px">
          
        </el-table-column>
        <el-table-column prop="wxAvatarUrl" label="头像" width="100px">
          <template slot-scope="scope">
            <el-image :src="scope.row.wxAvatarUrl" style="width: 70px;height: 70px;" :preview-src-list="[scope.row.wxAvatarUrl]"></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="userInfoVo.gender" label="性别">
          <template slot-scope="scope" v-if="scope.row.userInfoVo">
            <div>{{ scope.row.userInfoVo.gender === 0 ? '女' : '男' }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="userInfoVo.height" label="身高" width="150px">
          <template slot-scope="scope" v-if="scope.row.userInfoVo">
            <span>{{ scope.row.userInfoVo.height ? scope.row.userInfoVo.height + 'cm' : scope.row.userInfoVo.height }}</span>
          </template>
        </el-table-column>
        <el-table-column  prop="userInfoVo.weight" label="体重" width="150px">
          <template slot-scope="scope" v-if="scope.row.userInfoVo">
            <span>{{ scope.row.userInfoVo.weight ? scope.row.userInfoVo.weight + 'kg' : scope.row.userInfoVo.weight }}</span>
          </template>
        </el-table-column>
        <el-table-column width="150px" prop="userInfoVo.birthday" label="生日">
          <template slot-scope="scope" v-if="scope.row.userInfoVo">
            <span>{{ scope.row.userInfoVo.birthday && scope.row.userInfoVo.birthday.split(' ')[0] }}</span>
          </template>
        </el-table-column>
        <el-table-column width="150px" prop="userInfoVo.state" label="居住地"></el-table-column>
        <el-table-column width="150px" prop="userInfoVo.location" label="详情地址"></el-table-column>
        <el-table-column prop="userInfoVo.nationality" label="国籍" width="150px"></el-table-column>
        <el-table-column width="150px" prop="userInfoVo.hometown" label="祖籍"></el-table-column>
        <el-table-column prop="userInfoVo.job" label="职业" width="150px"></el-table-column>
        <el-table-column prop="userInfoVo.profession" label="行业" width="150px"></el-table-column>
        <el-table-column prop="userInfoVo.salary" label="年收入" width="150px"></el-table-column>
        <el-table-column width="150px" prop="userInfoVo.hobby" label="爱好"></el-table-column>
        <el-table-column label="婚姻状况" width="150px">
          <template slot-scope="scope" v-if="scope.row.userInfoVo">
            <span>{{ marrigestatus[scope.row.userInfoVo.maritalStatus - 1] }}</span>
          </template>
        </el-table-column>
        <el-table-column label="是否抽烟" width="150px">
          <template slot-scope="scope" v-if="scope.row.userInfoVo">
            <span v-if="scope.row.userInfoVo.smoke == 0">不抽</span>
            <span v-if="scope.row.userInfoVo.smoke == 1">偶尔抽烟</span>
            <span v-if="scope.row.userInfoVo.smoke == 2">经常抽烟</span>
          </template>
        </el-table-column>
        <el-table-column label="是否喝酒" width="150px">
          <template slot-scope="scope" v-if="scope.row.userInfoVo">
            <span v-if="scope.row.userInfoVo.wine == 0">不喝酒</span>
            <span v-if="scope.row.userInfoVo.wine == 1">偶尔喝酒</span>
            <span v-if="scope.row.userInfoVo.wine == 2">经常喝酒</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="principal"
          label="负责人"
          align="center"
          width="150px"
        >
          <template slot-scope="scope">
            <el-select
              v-model="scope.row.principal"
              placeholder="请选择"
              clearable
            >
              <el-option
                v-for="item in principalList"
                :key="item.uid"
                :label="item.username"
                :value="item.username"
              >
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="状态" width="150px">
          <template slot-scope="scope">
            <span v-if="scope.row.audit===0">待审核</span>
            <span v-if="scope.row.audit===1">待审核</span>
            <span v-if="scope.row.audit===2">审核成功</span>
            <span v-if="scope.row.audit===-1">审核失败</span>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="150px" align="center">
          <template slot-scope="scope">
            <div class="operation_group">
              <div class="item" @click="extensionEdit(scope.row, scope.$index)">个人资料</div>
              <div class="item" @click="handleCheck(scope.row, 2)">通过</div>
              <div class="item" @click="handleCheck(scope.row, -1)">不通过</div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </template>
    <div class="pagination">
      <pagination
        :total="total"
        :size="queryInfo.size"
        :page="queryInfo.page"
        @selectSizeChange="selectSizeChange"
        @selectPageChange="selectPageChange"
      ></pagination>
    </div>
    <!-- 修改信息  = false-->
    <my-amend v-show="isAmend" @btnClose="isAmend2">
      <user-card
        ref="refs"
        :form="ruleForm"
        :state_1="state_1"
        :state_2="state_2"
        :state_3="state_3"
        :state_4="state_4"
        :selectArr="selectArr"
        @succeddEdit="succeddEdit"
        @clickHobby="clickHobby"
      ></user-card>
    </my-amend>
    <hobbiesCpm :hobbyStr="hobbyStr" ref="hobbiesCpm" @save="saveHobbies"></hobbiesCpm>
  </div>
</template>

<script>
import { request } from "../../API/request";
import hearderInput from "../../components/hearderInput/hearderInput.vue";
import Pagination from "../../components/pagination/pagination.vue";
import myAmend from "../../components/case/amend.vue";
import UserCard from "./child/userCard.vue";
import hobbiesCpm from './child/hobbiesCpm'

import { debounce, throttle } from "../../components/code/common";
import { nextTick } from "process";



export default {
  name: '',
  components: { hearderInput, Pagination, myAmend, UserCard, hobbiesCpm },

  data () {
    return {
      selectArr: [],
      hobbyStr: '',
      state_1: '',
      state_2: '',
      state_3: '',
      state_4: '',
      userList: [],
      queryInfo: {
        page: 1, //当前页数
        size: 5, //一页显示多个条数据
      },
      // 分页的总条数
      total: 0,
      principalList: [], // 负责人列表
      marrigestatus: ['未婚', '已婚', '丧偶', '离异无孩', '离异不带孩', '离异带孩'],

      isAmend: false, // 修改内容组件

      ruleForm: {}, // 修改弹框
      edit: false, // 弹框属性默认为添加0
      editIndex: 0, // 编辑的列表索引

      wxUserId: '',
      userName: ''
    }
  },
  created () {
    request({
      url: "/user/getMember",
      method: "GET",
    }).then((res) => {
      console.log(res);
      if (res.code === 200) {
        this.principalList = res.data
        }
      })
      this.getUserList()
  },
  methods: {
    // 修改表头的样式
    headClass() {
      return "background:#fff7f7;";
    },
    searchBywxUsername (e) {
      this.wxUserId = e
      this.getUserList()
    },
    searchByusername (e) {
      this.userName = e
      this.getUserList()
    },
    getUserList () {
      request({
        url: "/wxCustomer/page",
        method: "GET",
        params: {
          page: this.queryInfo.page,
          size: this.queryInfo.size,
          wxUserId: this.wxUserId,
          userName: this.userName
        },
      }).then((res) => {
        console.log(res);
        if (res.code === 200) {
          let records = res.data.records
          records.forEach(item => {
            item.principal = ''
          })
          console.log(records);
          this.userList = records
          if (this.userList.length === 0) {
            this.$message.warning("不存在该数据");
          }
          this.total = res.data.total
        } else {
          this.$message.error("不存在该数据");
        }
      });
    },

    handleCheck (item, status) {
      console.log(item);
      if (!item.principal) {
        this.$notify.info({
          title: '消息',
          message: '请选择负责人'
        })
        return
      } else {
        const userInfo = localStorage.getItem('userInfo')
        const uid = JSON.parse(userInfo).uid
        console.log(uid)
        let that = this;
        let text = ''
        if (status === 2) {
          text = '审核通过'
        }
        if (status === -1) {
          text = '审核不通过'
        }
        const params = {
          audit: status,
          principal: item.principal,
          uid,
          // wxUserId: item.wxUserId
          wxUserId: item.userId
        }
        console.log(params)
        that
          .$confirm(text, "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "error",
          })
          .then(() => {
            setTimeout(() => {
              request({
                url: "/wxCustomer/auditUser",
                method: "POST",
                data: params,
              }).then((res) => {
                if (res.code === 200) {
                  that.$message.success("修改审核状态成功！");
                  that.getUserList()
                } else {
                  this.$message.error(res.message);
                }
              });
            }, 10);
          })
          .catch(() => {
            that.$message({
              type: "info",
              message: "已取消操作",
            });
          });
      }
    },
    
    // 分页中每页显示几条所触发的回调
    selectSizeChange(e) {
      this.queryInfo.size = e;
      this.getUserList();
    },
    // 当前第几页所触发的回调
    selectPageChange(e) {
      this.queryInfo.page = e;
      this.getUserList();
    },
    isAmend2() {
      this.isAmend = false;
      this.$refs.refs.disabled = true;
    },
    // 修改用户信息
    extensionEdit(row, index) {
      console.log(row,'row');
      this.disabledShow = true;
      this.edit = true;
      this.editIndex = index;
      this.ruleForm = JSON.parse(JSON.stringify(row.userInfoVo));
      if(row.userInfoVo.state) {
        this.state_1 = row.userInfoVo.state.slice(0, 2)
        this.state_2 = row.userInfoVo.state.slice(2)
      }
      if(row.userInfoVo.hometown) {
        this.state_3 = row.userInfoVo.hometown.slice(0, 2)
        this.state_4 = row.userInfoVo.hometown.slice(2)
      }
      console.log(this.state_1, this.state_2, this.state_3, this.state_4)
      this.isAmend = true;
    },
    // 确认修改信息回调
    succeddEdit: debounce(function (e) {
      console.log('............', e);
      let params = {
        userName: e.userName,
        gender: e.gender,
        birthday: e.birthday,
        height: e.height,
        weight: e.weight,
        state: e.state,
        location: e.location,
        nationality: e.nationality,
        hometown: e.hometown,
        job: e.job,
        profession: e.profession,
        education: e.education,
        salary: e.salary,
        maritalStatus: e.maritalStatus,
        emotionalExp: e.emotionalExp,
        smoke: e.smoke,
        wine: e.wine,
        hobby: e.hobby,
        declaration: e.declaration,
        wxUserId: e.wxUserId,
        isExp: 1
      };
      console.log(params);
      request({
        url: "/userInfo/updateUserInfo",
        method: "POST",
        data: params
      }).then((res) => {
        console.log("编辑");
        console.log(res);
        if (res.code === 200) {
          this.$message.success("修改操作成功！");
          this.getUserList();
          this.isAmend = false;
        } else {
          this.$message.error(res.message);
        }
      });
    }, 300),
    saveHobbies (event) {
      this.selectArr = event
    },
    clickHobby(event) {
      console.log('............', event);
      this.$nextTick(() => {
        this.hobbyStr = event
        this.$refs.hobbiesCpm.visible = true
      })
    }
  }
}
</script>

<style scoped lang=less>
.userCheck {
  background-color: #ffffff;
  padding: 24px;
  height: 93%;
  position: relative;
  .header {
    display: flex;
    .search {
      width: 250px;
    }
  }
}
.el-table {
  margin-top: 24px;
}
.operation_group {
  color: #155bd4;
  cursor: pointer;
  display: flex;
  .item {
    flex: 1;
    display: flex;
    justify-content: center;
  }
}
.isTitle {
  font-size: var(--mainSize);
  text-align: center;
  margin-bottom: 12px;
}
.content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 12px;
  font-size: var(--subSize);
  .leftText {
    width: 100px;
  }
  .upPhotoList {
    position: relative;
    margin: 20px 20px 0 0;
    .close {
      position: absolute;
      top: -12px;
      right: -12px;
      .close_icon {
        width: 24px;
        height: 24px;
      }
    }
  }
  .cardList {
    display: flex;
    flex-wrap: wrap;
  }
  .video {
    width: 180px;
    height: 300px;
    display: block;
  }
  .avatar-uploader {
    margin-top: 20px;
  }
  .el-upload {
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    display: flex;
    &:hover {
      border-color: #409eff;
    }
    .avatar-uploader-icon {
      border: 1px dashed #999999;
      font-size: 28px;
      color: #999999;
      width: 105px;
      height: 105px;
      line-height: 105px;
      text-align: center;
    }
  }
}
.avatar {
  width: 105px;
  height: 105px;
  display: block;
}
.btn {
  width: 100%;
  display: flex;
  margin-top: 20px;
  justify-content: center;
  .affirm {
    background: var(--pink);
    color: var(--white);
  }
}

</style>